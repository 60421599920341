<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="popsLista"
          sort-by="nome"
          class="elevation-10"
          :search="search"
          :loading="!popsProgress.length"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="pop_titulo"
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-calendar-check-outline</v-icon>
              <v-toolbar-title>Pops</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selecionarPopEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Pop</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.cria_usuario`]="{ item }">
            <span>{{ obterUsuario(item.cria_usuario) }} </span>
          </template>
          <template v-slot:[`item.cria_datahora`]="{ item }">
            <span>{{ mixDataDBD(item.cria_datahora) }} </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="selecionarPopEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
import moment from "moment";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("utilPops");

export default {
  mixins: [DatasMixin],
  name: "UtilPopsLista",
  data: () => ({
    listaCliAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },
      {
        text: "Titulo",
        align: "start",
        sortable: true,
        value: "pop_titulo",
      },
      { text: "Criador", value: "cria_usuario", align: "start" },
      { text: "Data Hora", value: "cria_datahora", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["erro", "pops", "popSelecionado", "listaUsuarios"]),
    ...mapGetters(["popsAtivos", "popsInativos"]),

    popsLista() {
      return this.$store.state.utilPops.pops;
    },
    popsProgress() {
      return this.$store.state.utilPops.pops;
    },
  },

  created() {
    register(this.$store);
    this.listarPops(this.$store.state.login.licenca);
    this.listarUsuarios(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterPops();
  },

  methods: {
    ...mapActions([
      "deletarPop",
      "listarPops",
      "selecionarPop",
      "resetarPop",
      "ativarPop",
      "listarUsuarios",
    ]),

    obterUsuario(id) {
      if (id != null || id != 0) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        if (ret.length != 0) {
          return ret[0].usuario;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    permissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    formataData(data) {
      return moment(data).utc().format("DD-MM-YYYY");
    },
    formataDataIn(data) {
      return moment(data).utc().format("YYYY-MM-DD");
    },

    ativoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    login() {
      this.$router.push({ name: "Login" });
    },

    novoPop() {
      if (this.popSelecionado) {
        this.resetarPopSelecionado();
        return;
      }
    },

    selecionarPopEdicao(pop) {
      this.selecionarPop({ pop });
      this.$router.push({ name: "UtilPopsForm" });
    },

    resetarPop() {
      this.resetarPopSelecionado();
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.listarPops(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
